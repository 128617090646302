/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

.panel-table {
    position: relative;
}

.p-datatable-wrapper{
    overflow-x: auto !important;
}

.panel-table-dt-component.loading {
    pointer-events: none;
    opacity: 0.3;
}

.panel-table.loading {
    min-height: 100px;
}

.panel-table.loading > .loading-component {
    position: absolute;
    z-index: 1;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
}