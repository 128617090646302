/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

.sign-in {
    background: linear-gradient(145deg, #cad1da, #aab0b8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;

    width: 100%;
    height: 100%;
    max-width: 100vw;
}

.sign-in>.container {
    /* height: 370px; */
    width: 350px;
    border-radius: 20px;
    background: linear-gradient(145deg, #cad1da, #aab0b8);
    box-shadow: 32px 32px 65px #9da2a9,
    -32px -32px 65px #dde4ef;
}

.sign-in>.container>.title {
    width: 100%;
    text-align: center;

    font-size: 2.5em;

    margin-top: 40px;
}

.sign-in>.container>.title>span {
    color: #3b3e47;
}

.sign-in>.container>.title>span::before {
    content: 'Login';
}

.sign-in>.container>.form {
    width: 100%;
    height: 150px;

    margin: 30px 0 30px 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sign-in>.container>.form-msal {
    width: 100%;
    margin: 100px 10px 0px 0px;

    display: flex;
    justify-content: center;
}

.sign-in>.container>.form .p-float-label {
    margin: 15px 5% 15px 5%;
}

.sign-in>.container>.form .p-inputtext {
    width: 100%;
    border-radius: 10px;
}

.sign-in>.container>.form .sign-in-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.sign-in>.container>.form .sign-in-btn>button {
    margin-right: 5%;
}