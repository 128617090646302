/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

.p-panelmenu-panel:focus {
    outline: none;
}

.p-panelmenu-header > a:focus {
    box-shadow: none !important;
}

.p-panelmenu-content {
    border: none !important;
    margin-bottom: 0 !important;
}

.p-panelmenu-header > a {
    border-radius: 0 !important;
    background-color: inherit !important;
    border: none !important;
    border-bottom: 1px solid #ffe91e;
}

.p-panelmenu-header > a .p-panelmenu-icon {
    color: #fff !important;
}

.p-panelmenu-header:not(.p-highlight) > a:hover .p-panelmenu-icon {
    color: #848484 !important;
}

.p-panelmenu-header-link {
    color: #fff !important;
}

.p-panelmenu .p-menuitem-text {
    color: #fff !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover {
    background: #4d505b !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-menuitem-text {
    color: #ffffff !important;
}

.p-panelmenu .p-panelmenu-content {
    background-color: #3b3e47 !important;
    padding: 0 !important;
}

.p-panelmenu.p-component {
    background-color: #4d505b;
}

.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
    border: 1px solid #4d505b;
    color: #495057;
    background: #4d505b;
    border-radius: 3px;
    transition: box-shadow 0.2s;
}