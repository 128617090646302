#chart-panel {
    width: 100%;
    display: flex;
    justify-content: center;
}

#chart-message {
    text-align: center;
}

.legendtext {
    font-size: 12px !important;
    font-family: Nunito !important;
}

.drag{
    font-size: 12px !important;
    font-family: Nunito !important;
}