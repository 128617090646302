/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

.dashboard {
    display: flex;
    flex-direction: row;
    position: absolute;

    width: 100%;
    height: 100%;
    max-width: 100vw;
}

main {
    flex-grow: 1;
    overflow: auto;
}

#content {
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: auto;
    overflow-y: scroll;
    background-color: #EDF0F5;
}

.container {
    margin: 20px;
}


.p-dropdown {
    min-height: 38px;
}