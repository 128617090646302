/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

.navbar {
    background: linear-gradient(90deg,#fff,#fff,#fff,#80ff80,#8080ff);;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    height: 50px;
    /* margin-left: 27.3vh; */
    /*position: absolute;*/
    /*top: 0;*/
    /*right: 0;*/
    /*z-index: 1000;*/
}

.navbar>.menu {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 50px;
}

.navbar>.menu>i {
    font-size: 1.5em;
}

.navbar>.profile-picture {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
