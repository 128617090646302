.node circle {
    fill: steelblue;
    stroke: grey;
    stroke-width: 2px;
}

.node text {
    font: 10px sans-serif;
}

/* link is for the lines */

.link {
    fill: none;
    stroke: #ccc;
    stroke-width: 2px;
    z-index: -1;
}

.axis path,
.axis line {
    /* fill: none; */
    stroke: slategray;
    shape-rendering: crispEdges;
}

.axis text {
    font: 11px sans-serif;
}
 
div.tooltip {
    position: absolute;
    text-align: center;
    max-width: 160px;
    padding: 7px 10px;
    font: 12px sans-serif;
    color: white;
    background: #111;
    border: 0px;
    border-radius: 2px;
    pointer-events: none;
    margin-top: 2px;
}