/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

[data-reach-menu-popover] {
    z-index: 99999;
}

[data-reach-menu-button] {
    cursor: pointer;
    margin: 0;
    color: #ffffff;
    background-color: #007ad9;
    border: 1px solid #007ad9;
    /* font-size: 14px; */
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    /* padding: 0.429em 1em; */
}

[data-reach-menu-button]:focus {
    outline: none;
}

[data-reach-menu-button]:hover {
    background-color: #0061af;
    transform: scale(.99);
}

[data-reach-menu-button][aria-expanded="true"] {
    background-color: #0061af;
    transform: scale(.99);
}