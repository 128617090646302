.tab-view {
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
  display: flex;
  padding: 0;
  margin: 0;
}

.tab-panel {
  background-color: #fff;
  padding: 1rem;
}

.tab-view li {
  list-style-type: none;
  margin-left: 2px;
  margin-right: 2px;
}

.tab-view li > button {
  cursor: pointer;
  padding: 1rem;
  background: none;
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 -2px 0;
  transition: box-shadow 0.2s;
}

.tab-view li > button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}