.p-dialog .p-dialog-content {
    overflow: auto !important; 
    padding: 15px 1.5rem 2rem 1.5rem;
}

.p-datatable {
    overflow-x: visible !important;
}

.p-dialog-content-help > .p-dialog-content {
    overflow: auto !important; 
}

/* .p-datatable-scrollable-body {
    overflow: scroll !important; max-height: 300px !important;
} */

.p-datatable-resizable .p-datatable-thead>tr>th {
    overflow: hidden;
    white-space: unset !important;
}

.p-grid.p-justify-between:empty{
    display:none;
}