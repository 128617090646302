/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

.company-logo {
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    background: #fff;
}

.company-logo>.logo-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.company-logo>.logo-wrapper>img {
    width: 50%;
}
