.trace {
    cursor: pointer;
}

.xtick > text{
    font-size: 12px !important;
}

.ytick > text{
    font-size: 12px !important;
}

/* #chart-panel > div > div > div > svg:nth-child(1) > g.cartesianlayer > g > g.xaxislayer-above > g:nth-child(1) > text */