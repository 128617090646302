.p-datatable-tbody tr:hover {
  filter: brightness(0.95);
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 5px !important;
}

.p-datatable .p-datatable-tbody > tr > td.expander-column {
  padding: 0px !important;
}

/* .p-datatable .p-datatable-tbody > tr > td.p-selection-column {
  padding: 0px !important;
} */

.p-datatable-thead > tr > th.p-selection-column {
  max-width: 40px !important;
}

.p-datatable .p-datatable-tbody > tr > td.p-selection-column {
  max-width: 40px !important;
}

.export-buttons > .p-button-icon-only {
  margin: 2px;
}

.p-column-title {
  font-size: 12px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  word-wrap:break-word;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init {
  display: none;
}