/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 


@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Nunito/Nunito-Regular.ttf'); /* IE9 Compat Modes */
  src: local('Nunito'),
       url('../assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype')
}

html, body {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont;
  font-size: 14px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-size: 14px;
}

.p-component {
  font-family: "Nunito" !important;
}

.p-menuitem-text {
  font-family: "Nunito" !important;
  font-size: 14px !important;
}

.p-panelmenu .p-panelmenu-header > a {
  font-size: 14px !important;
  font-weight: 100 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{word-break: break-all}

.node text {
  font: 10px Nunito !important;
}

.axis text {
  font: 10px Nunito !important;
}

.tree-chart-title{
  font: 10px Nunito !important;
}

.p-editor-container {
  height: 100% !important;
}

p {
  word-break: unset;
  margin-block-start: 0em;
  margin-block-end: 0em;
}