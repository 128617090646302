.mapboxgl-ctrl-attrib-inner {
    visibility: hidden !important;
}

.mapboxgl-popup {
    position: absolute;
    top: 21px;
    box-shadow: 3px 3px #8888884d;
}

.mapboxgl-canvas {
    width: 100% !important;
    /* height: 100% !important; */
}