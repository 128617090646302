/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

.context-menu-items {
    position: fixed;
    border: 1px solid black;
    background-color: white
}

.context-menu-items .context-menu-item {
    padding: 4px;
}

.context-menu-items .context-menu-item:not(:first-child) {
    border-top: 1px solid black;
}

.context-menu-items .context-menu-item:hover {
    background-color: lightblue;
    cursor: pointer;
}