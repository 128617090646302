/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

#sidebar {
    /*background-color: #5c5c5c;*/
    /*padding: 0.571em 0;*/
    padding: 0;
    width: 250px;
    min-width: 250px;
    max-width: 250px;

    position: relative;
    color: #495057;
    background: #4d505b;
    background: --webkit-gradient(linear,left top,left bottom,from(#4d505b),to(#3b3e47));
    background: linear-gradient(180deg,#4d505b 0,#3b3e47);

    border: none;
}

#sidebar .title {
    background-color: #ffffff;

    width: 100%;
    height: 25px;

    text-align: center;

    padding: 2px 0;
}

#sidebar .title>p {
    margin: 0;
}

.p-sidebar .p-sidebar-header {
    padding: 0rem !important;
}

.p-sidebar .p-sidebar-content {
    padding: 0rem !important;
}