/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */ 

.widget {
    padding: 0.5em 1em;

    width: 95%;
}


.grid{
    display: grid;
    margin: auto;
    gap: 1px 1px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
        "LINE3flr LINE1flr LINE1flr"
        "LINE3flr LINE1flr LINE1flr"
        ". . .";
}